import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EarthDownloadsPage = ({ location }) => (
  <Layout location={location} title="Earth Downloads">
    <SEO title="Earth Downloads" />
    <section id="earth-downloads-page" className="page">
      <div className="container text-white">
        <div className="content bg-black p-5">
          <p>
            Here are some resources for you to explore more Earth images from
            Space and the collections that are available for public use.
          </p>
          <ul className="list-unstyled">
            <li>
              <a
                href="/https://images.nasa.gov/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                Main NASA Image Library
              </a>
            </li>
            <li>
              <a
                href="https://visibleearth.nasa.gov/collection/1484/blue-marble "
                target="_blank"
                rel="noopener noreferrer"
              >
                NASA Blue Marble
              </a>
            </li>
            <li>
              <a
                href="https://visibleearth.nasa.gov/"
                target="_blank"
                rel="noopener noreferrer"
              >
                NASA Visible Earth
              </a>
            </li>
            <li>
              <a
                href="https://earthobservatory.nasa.gov/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Earth Observatory
              </a>
            </li>
            <li>
              <a
                href="https://epic.gsfc.nasa.gov/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                DSCOVR EPIC
              </a>
            </li>
            <li>
              <a
                href="http://blueturn.earth/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                Blue Turn
              </a>
            </li>
            <li>
              <a
                href="https://www.nasa.gov/connect/apps.html "
                target="_blank"
                rel="noopener noreferrer"
              >
                NASA APPS for viewing Earth
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
)

export default EarthDownloadsPage
